import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import { useTranslation, Trans } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import headerImg from '../assets/img/me.png';
import 'animate.css';

export const Banner = () => {
  const { t } = useTranslation();
  const toRotate = ['Frontend Engineer', 'Backend Engineer', 'Team Leader'];
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                <h1>{t('banner.wave')} <br/>
              <Typewriter
                options={{
                  strings: toRotate,
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 30,
                }}
              />
                </h1>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => <div className={isVisible ? 'animate__animated animate__zoomIn' : ''}>
                  <img src={headerImg} alt="Header Img" className='my-logo'/>
                  <p className='didascalia-banner-image'><Trans i18nKey="banner.didascalia" /></p>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
