import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import logo from '../assets/img/LDRwhite.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import gitHub from '../assets/img/github-mark-white.svg';

export const Footer = () => {
  useTranslation();
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" className="logo-footer" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/lorenzo-del-rossi-76517418b/" target="_blank" rel="noreferrer" ><img src={navIcon1} alt="linkedin" /></a>
              <a href="https://github.com/LorenzoDelRossi"><img src={gitHub} target="_blank" alt="github" rel="noreferrer" /></a>
            </div>
            <p><Trans i18nKey="footer.text"
              values={{ year: new Date().getFullYear() }}
              components={{ linkm: <a className="footer-link" href="https://reactjs.org/" target="_blank" rel="noreferrer" /> }}/></p>

          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
