import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageButton = () => {
  const { i18n } = useTranslation();
  return (<div className='flagcontainer'><button type="button" className='switch' onClick={() => i18n.changeLanguage(i18n.language === 'it' ? 'en' : 'it')}>
<p className="language-text">{i18n.language === 'en' ? 'Italiano' : 'English'}</p>
</button></div>);
};

export default LanguageButton;
