import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';
import colorSharp from '../assets/img/color-sharp.png';

export const Skills = () => {
  const { t } = useTranslation();
  return (
    <section className="skill" id="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>{t('skills.about')}</h2>
              <div>
            <p>{t('skills.text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="lorenzo-del-rossi-skills" />
    </section>
  );
};

export default Skills;
