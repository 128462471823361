import mongo from '../assets/img/mongo.png';
import mySql from '../assets/img/mysql.webp';
import postgres from '../assets/img/postgres.png';
import fireBase from '../assets/img/firebase.jpeg';
import prisma from '../assets/img/prisma.png';
import nest from '../assets/img/nest.png';
import js from '../assets/img/js.png';
import ts from '../assets/img/ts.png';
import python from '../assets/img/python.png';
import golang from '../assets/img/golang.webp';
import java from '../assets/img/java.png';
import c from '../assets/img/c.jpeg';
import cplusplus from '../assets/img/cplusplus.png';
import drone from '../assets/img/drone.jpeg';
import svd from '../assets/img/svd.jpeg';
import react from '../assets/img/react.png';
import gcp from '../assets/img/gcp.png';
import dbt from '../assets/img/dbt.webp';
import dataform from '../assets/img/dataform.webp';
import i2c from '../assets/img/i2c.png';

export const LANGUAGES = [
  {
    titleTransKey: 'projects.languages.js.title',
    descriptionTransKey: 'projects.languages.js.description',
    title: 'Javascript',
    description: 'Frontend + Backend Development',
    imgUrl: js,
    website: 'https://www.javascript.com/',
  },
  {
    titleTransKey: 'projects.languages.ts.title',
    descriptionTransKey: 'projects.languages.ts.description',
    title: 'Typescript',
    description: 'Frontend + Backend Development',
    imgUrl: ts,
    website: 'https://www.typescriptlang.org/',
  },
  {
    titleTransKey: 'projects.languages.python.title',
    descriptionTransKey: 'projects.languages.python.description',
    title: 'Python',
    description: 'Frontend + Backend Development',
    imgUrl: python,
    website: 'https://www.python.org/',
  },
  {
    titleTransKey: 'projects.languages.go.title',
    descriptionTransKey: 'projects.languages.go.description',
    title: 'GoLang',
    description: 'Backend Development',
    imgUrl: golang,
    website: 'https://go.dev/',
  },

  {
    titleTransKey: 'projects.languages.java.title',
    descriptionTransKey: 'projects.languages.java.description',
    title: 'Java',
    description: 'Backend Development',
    imgUrl: java,
    website: 'https://www.java.com/',
  },
  {
    titleTransKey: 'projects.languages.c.title',
    descriptionTransKey: 'projects.languages.c.description',
    title: 'C',
    description: 'Backend Development',
    imgUrl: c,
    website: 'https://www.open-std.org/jtc1/sc22/wg14/',
  },
  {
    titleTransKey: 'projects.languages.cpp.title',
    descriptionTransKey: 'projects.languages.cpp.description',
    title: 'C++',
    description: 'Backend Development',
    imgUrl: cplusplus,
    website: 'https://isocpp.org/',
  },
];

export const TOOLS = [
  {
    titleTransKey: 'projects.toolspr.react.title',
    descriptionTransKey: 'projects.toolspr.react.description',
    title: 'React.js',
    description: 'Frontend Development',
    imgUrl: react,
    website: 'https://reactjs.org/',
  },
  {
    titleTransKey: 'projects.toolspr.dbt.title',
    descriptionTransKey: 'projects.toolspr.dbt.description',
    title: 'Data Build Tool',
    description: 'Data Transformations',
    imgUrl: dbt,
    website: 'https://www.getdbt.com/',
  },
  {
    titleTransKey: 'projects.toolspr.dataform.title',
    descriptionTransKey: 'projects.toolspr.dataform.description',
    title: 'Dataform',
    description: 'Data modeling',
    imgUrl: dataform,
    website: 'https://dataform.co/',
  },
  {
    titleTransKey: 'projects.toolspr.gcp.title',
    descriptionTransKey: 'projects.toolspr.gcp.description',
    title: 'Google Cloud Platform',
    description: 'Cloud Computing Services',
    imgUrl: gcp,
    website: 'https://cloud.google.com/',
  },
  {
    titleTransKey: 'projects.toolspr.nest.title',
    descriptionTransKey: 'projects.toolspr.nest.description',
    title: 'Nest JS',
    description: 'NodeJS API Development Framework',
    imgUrl: nest,
    website: 'https://nestjs.com/',
  },
  {
    titleTransKey: 'projects.toolspr.prisma.title',
    descriptionTransKey: 'projects.toolspr.prisma.description',
    title: 'Prisma',
    description: 'ORM',
    imgUrl: prisma,
    website: 'https://www.prisma.io/',
  },
  {
    titleTransKey: 'projects.toolspr.i2c.title',
    descriptionTransKey: 'projects.toolspr.i2c.description',
    title: 'I2C Protocol',
    description: 'Serial Communication',
    imgUrl: i2c,
    website: 'https://wikipedia.org/wiki/I%C2%B2C',
  },
];

export const DATABASES = [
  {
    titleTransKey: 'projects.databases.mysql.title',
    descriptionTransKey: 'projects.databases.mysql.description',
    title: 'MySQL',
    description: 'Relational Database',
    imgUrl: mySql,
    website: 'https://www.mysql.com/',
  },
  {
    titleTransKey: 'projects.databases.postgres.title',
    descriptionTransKey: 'projects.databases.postgres.description',
    title: 'PostgreSQL',
    description: 'Relational Database',
    imgUrl: postgres,
    website: 'https://www.postgresql.org/',
  },
  {
    titleTransKey: 'projects.databases.mongo.title',
    descriptionTransKey: 'projects.databases.mongo.description',
    title: 'MongoDB',
    description: 'Non-Relational Database',
    imgUrl: mongo,
    website: 'https://www.mongodb.com/',
  },
  {
    titleTransKey: 'projects.databases.fb.title',
    descriptionTransKey: 'projects.databases.fb.description',
    title: 'Google Firebase',
    description: 'Non-Relational Database',
    imgUrl: fireBase,
    website: 'https://firebase.google.com/',
  },
];

export const PAPERS = [
  {
    titleTransKey: 'projects.papers.svd.title',
    descriptionTransKey: 'projects.papers.svd.description',
    title: 'SVD of Data Matrices on Curved Space',
    description: 'Computer Vision & Machine Learning',
    imgUrl: svd,
    website: 'https://www.mdpi.com/2079-9292/9/2/334',
  },
  {
    titleTransKey: 'projects.papers.drone.title',
    descriptionTransKey: 'projects.papers.drone.description',
    title: 'Drone PD controller on Lie Groups',
    description: 'Automation & Machine Learning',
    imgUrl: drone,
    website: 'https://www.tandfonline.com/doi/full/10.1080/00207179.2020.1780474',
  },
];
