import React from 'react';
import TrackVisibility from 'react-on-screen';
import {
  Container, Row, Col, Tab, Nav,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ProjectCard } from './ProjectCard';
import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import {
  LANGUAGES, TOOLS, PAPERS, DATABASES,
} from '../db/projects';

export const Projects = () => {
  const { t } = useTranslation();
  return (<section className="project" id="expertise">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                <h2 className="expertise-title">{t('projects.title')}</h2>
                <Tab.Container id="expertise-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t('projects.lang')}</Nav.Link>
                    </Nav.Item>
                    {
                    <>
                    <Nav.Item>
                      <Nav.Link eventKey="second">{t('projects.tools')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t('projects.db')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">{t('projects.research')}</Nav.Link>
                    </Nav.Item>
                    </>
                    }
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? 'animate__animated animate__slideInUp' : ''}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          LANGUAGES.map((project, index) => (
                              <ProjectCard
                                t={t}
                                key={index}
                                {...project}
                                />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          TOOLS.map((tool, index) => (
                              <ProjectCard
                                t={t}
                                key={index}
                                {...tool}
                                />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                      <Tab.Pane eventKey="third">
                    <Row>
                        {
                          DATABASES.map((db, index) => (
                              <ProjectCard
                                t={t}
                                key={index}
                                {...db}
                                />
                          ))
                        }
                      </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                    <Row>
                        {
                          PAPERS.map((paper, index) => (
                              <ProjectCard
                                t={t}
                                key={index}
                                {...paper}
                                />
                          ))
                        }
                      </Row>
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="lorenzo-projects-section"></img>
    </section>
  );
};

export default Projects;
