import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ProjectCard = ({
  title, imgUrl, website, titleTransKey, descriptionTransKey, t,
}) => (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={() => window.open(website, '_blank').focus()}>
        <img className="project-image" src={imgUrl} alt={`${title}-img-url`}/>
        <div className="proj-txtx">
          <h4>{t(titleTransKey)}</h4>
          <span>{t(descriptionTransKey)}</span>
        </div>
      </div>
    </Col>
);

ProjectCard.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  website: PropTypes.string,
  titleTransKey: PropTypes.string,
  descriptionTransKey: PropTypes.string,
  t: PropTypes.func,
};

export default ProjectCard;
