import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/img/LDRwhite.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import gitHub from '../assets/img/github-mark-white.svg';

export const NavBar = () => {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? 'scrolled' : ''}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Lorenzo Del Rossi logo" className="logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('home')}>{t('navbar.home')}</Nav.Link>
              <Nav.Link href="#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('about')}>{t('navbar.about')}</Nav.Link>
              <Nav.Link href="#expertise" className={activeLink === 'expertise' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('expertise')}>{t('navbar.expertise')}</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/lorenzo-del-rossi-76517418b/" target="_blank" rel="noreferrer"><img src={navIcon1} alt="linkedin" /></a>
                <a href="https://github.com/LorenzoDelRossi"><img src={gitHub} target="_blank" alt="github" /></a>
              </div>
              <HashLink to='#connect'>
              <button className="vvd" onClick={(e) => {
                window.location.href = 'mailto:info@lorenzodelrossi.com';
                e.preventDefault();
              }}>
              <span>{t('navbar.chat')}</span>
              </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};

export default NavBar;
