import React from 'react';
import { parseISO, format } from 'date-fns';
import PropTypes from 'prop-types';
import { it, enUS } from 'date-fns/locale';
import career from '../db/career';

const getLocale = (locale) => {
  switch (locale) {
    case 'it':
      return { localeDate: it, presentString: 'Presente' };
    case 'en':
    default:
      return { localeDate: enUS, presentString: 'Present' };
  }
};

export const ExperienceRows = ({ t, locale }) => {
  const { localeDate, presentString } = getLocale(locale);
  return career.map((item, index) => (
  <div className="experience-row"key={index}>
    <h3>{t(item.titleTransKey)}</h3>
    <p>
      <a className="experience-link" href={item.url} target="_blank" rel="noreferrer">
        {t(item.companyTransKey)}
      </a>
      </p>
      {item.description && <p>{t(item.descriptionTransKey)}</p>}
      {item.externalResourceComponent ? item.externalResourceComponent(t) : undefined}
      {item.techStack && <p>{item.techStack.join(', ')}</p>}
    <p>
      <span>{format(parseISO(item.startDate), 'LLL yyyy', { locale: localeDate })}</span>
      <span> - </span>
      <span>
        {item.endDate
          ? format(parseISO(item.endDate), 'LLL yyyy', { locale: localeDate })
          : presentString}
      </span>
    </p>
  </div>
  ));
};

ExperienceRows.propTypes = {
  t: PropTypes.func,
  locale: PropTypes.string,
};

export default ExperienceRows;
