export const CAREER = [
  {
    titleTransKey: 'career.capchase.title',
    descriptionTransKey: 'career.capchase.description',
    companyTransKey: 'career.capchase.company',
    title: 'Full-Stack Software Engineer',
    company: 'Capchase',
    url: 'https://www.capchase.com/',
    description: 'From React to DBT, I work on the whole tech stack of this amazing FinTech company',
    startDate: '2022-02-14',
  },
  {
    titleTransKey: 'career.hoc.title',
    descriptionTransKey: 'career.hoc.description',
    companyTransKey: 'career.hoc.company',
    title: 'CTO - COO',
    company: 'House of Codes',
    description: ' ',
    url: 'https://www.houseofcodes.it/',
    startDate: '2021-01-01',
  },
  {
    titleTransKey: 'career.freelance.title',
    descriptionTransKey: 'career.freelance.description',
    companyTransKey: 'career.freelance.company',
    title: 'Freelancer',
    company: '',
    url: '',
    description: 'Python and GoLang developer, building serverless infrastructures using Google Cloud Platform',
    startDate: '2018-10-01',
    endDate: '2021-01-09',
  },
  {
    titleTransKey: 'career.unimag.title',
    descriptionTransKey: 'career.unimag.description',
    companyTransKey: 'career.unimag.company',
    title: "Master's Degree in Computer Engineering",
    company: 'University of Modena and Reggio Emilia',
    url: 'https://www.unimore.it/',
    description: "Master's Degree - specialization in Artificial Intelligence - grade 110/110",
    startDate: '2019-09-11',
    endDate: '2021-12-02',
  },
  {
    titleTransKey: 'career.unitri.title',
    descriptionTransKey: 'career.unitri.description',
    companyTransKey: 'career.unitri.company',
    title: "Bachelor's Degree in Computer and Automation Engineering",
    company: 'Università Politecnica delle Marche',
    url: 'https://www.univpm.it/',
    description: 'Bachelor\'s Degree - grade 104/110',
    startDate: '2016-09-11',
    endDate: '2019-07-02',
    // eslint-disable-next-line react/react-in-jsx-scope
    externalResourceComponent: (t) => <a className="experience-link"
    href="https://www.tandfonline.com/doi/abs/10.1080/00207179.2020.1780474?journalCode=tcon20">
    {t('career.thesis_published')}</a>,
  },
];

export default CAREER;
