import React from 'react';
import TrackVisibility from 'react-on-screen';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ExperienceRows } from './ExperienceRows';
import contactImg from '../assets/img/contact-img.svg';
import 'animate.css';

export const Experience = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="experience" id="connect">
      <Container>
        <Row>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => <img className={isVisible ? 'animate__animated animate__fadeInLeftBig' : ''} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => <><div className={isVisible ? 'animate__animated animate__fadeInUpBig' : ''}>
                <h2>{t('career.done')}</h2>
              <ExperienceRows t={t} locale={i18n.language} />
              </div>
              </>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Experience;
